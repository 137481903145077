document.addEventListener("turbolinks:load", function () {
    const searchInput = document.getElementById("learnSearchInput");
    const searchButton = document.getElementById("learnSearchButton");
    const resultsList = document.getElementById("resultsList");

    if (!searchInput || !searchButton || !resultsList) return; // Exit if elements are missing

    // Hardcoded articles for caching
    const articles = [
        { title: "Ballot", type: "article", path: "/ballot_about" },
        { title: "Cumulative Voting", type: "article", path: "/cumulative_voting" },
        { title: "Detachable Page", type: "article", path: "/detachable_page" },
        { title: "Electronic Consent Form", type: "article", path: "/electronic_consent_forms" },
        { title: "Floor Nomination", type: "article", path: "/floor_nomination" },
        { title: "Inspector of Elections", type: "article", path: "/inspector_of_elections_about" },
        { title: "Irrevocable Ballot", type: "article", path: "/irrevocable_ballot" },
        { title: "Nomination Form", type: "article", path: "/nomination_form_about" },
        { title: "Pre-Ballot Notification", type: "article", path: "/pre_ballot_notification_about" },
        { title: "Proxy", type: "article", path: "/proxy_about" },
        { title: "Proxy", type: "guide", path: "/proxy_guide" },
        { title: "Proxy Grantor", type: "article", path: "/proxy_grantor" },
        { title: "Proxy Holder", type: "article", path: "/proxy_holder" },
        { title: "Record Date", type: "article", path: "/record_date" },
        { title: "Voter List", type: "article", path: "/voter_list_about" },
        { title: "Write-In Candidate", type: "article", path: "/write_in_candidate" }
    ];

    // Get the correct domain dynamically
    const baseURL = window.location.origin; // Auto-detects development, testing, or production URL

    function fetchSearchResults() {
        const query = searchInput.value.trim().toLowerCase();

        // Clear results if input is empty
        if (query.length === 0) {
            resultsList.innerHTML = "";
            resultsList.classList.add("opacity-0", "translate-y-2"); // Reset animation
            return;
        }

        // Filter articles based on the query (case-insensitive)
        const filteredArticles = articles
            .filter(article => article.title.toLowerCase().includes(query))
            .slice(0, 3); // Limit results to top 3

        // Clear previous results
        resultsList.innerHTML = "";

        if (filteredArticles.length > 0) {
            resultsList.classList.remove("opacity-0", "translate-y-2"); // Remove fade-out effect
            setTimeout(() => resultsList.classList.add("opacity-100", "translate-y-0"), 200); // Slight delay for animation

            // Populate results dynamically
            filteredArticles.forEach(article => {
                const listItem = document.createElement("a"); // Make the whole box a link
                listItem.href = `${baseURL}${article.path}`;
                listItem.className = "block w-full py-4 px-6 bg-white rounded-lg shadow-md hover:bg-gray-100 transition duration-300 ease-in-out flex justify-between items-center"; // Flex row layout

                // Inner content
                listItem.innerHTML = `
                    <div class="text-black font-bold">${article.title}</div>
                    <div class="text-gray-600 text-sm italic">${article.type}</div>
                `;

                resultsList.appendChild(listItem);
            });
        } else if (query.length >= 3) {
            // Show the no-results message when no matches are found and input has 3+ characters
            resultsList.classList.remove("opacity-0", "translate-y-2");
            setTimeout(() => resultsList.classList.add("opacity-100", "translate-y-0"), 200);

            const noResultsMessage = document.createElement("div");
            noResultsMessage.className = "block w-full py-4 px-6 bg-white rounded-lg shadow-md text-black";
            noResultsMessage.innerHTML = `
                There are no matches in the database for this search term.
                You might try searching for 'ballot' or 'vote' instead.
                <br>
                <a href="${baseURL}/all_resources" class="text-[#a146b4] font-semibold hover:underline">
                    Click here to browse all resources
                </a>
            `;

            resultsList.appendChild(noResultsMessage);
        }
    }

    // Search when typing (if input has 3+ characters or gets cleared)
    searchInput.addEventListener("input", function () {
        if (searchInput.value.trim().length >= 3 || searchInput.value.trim().length === 0) {
            fetchSearchResults();
        }
    });

    // Always search when clicking the button (ignores the 3-character limit)
    searchButton.addEventListener("click", fetchSearchResults);
});
